import { createReducer } from "@reduxjs/toolkit"
import { 
    fetchStatusLoading,
    fetchBalanceNFT,
    fetchTokenIds,
    fetchTokenInfo,
    fetchApprove,
    fetchNFTStaked,
    fetchStaked,
    fetchTimeStaked,
    fetchAmountStaked,
    fetchNFTIdList,
    fetchNFTTypeForStaked,
    fetchPenddingReward,
    fetchNftIdsUnStake,
    tabActive
} from "./actions"
import { NFTType, TokenId } from "./type"

interface globalStateNFTPool {
    balance: number,
    tokenIds:TokenId[],
    nftInfo:NFTType[],
    isLoading: boolean,
    isApprove: boolean
    listNFTToStaked:number[],
    isStaked: boolean,
    timeStaked: {
        startTimeStaked:number,
        endTimeStaked:number,
    },
    userAmountStaked: number,
    nftIdList: number[],
    nftInfoStaked:NFTType[],
    peddingReward:string,
    nftIdsUnStake: number[],
    isPoolV1:boolean
}

export const initialState: globalStateNFTPool = {
    balance: 0,
    tokenIds:[],
    nftInfo:[],
    isLoading: false,
    isApprove: false,
    listNFTToStaked:[],
    isStaked: false,
    timeStaked: {
        startTimeStaked:0,
        endTimeStaked:0,
    },
    userAmountStaked: 0,
    nftIdList:[],
    nftInfoStaked:[],
    peddingReward:"0",
    nftIdsUnStake: [],
    isPoolV1:!false
}

export default createReducer(initialState, (builder) => 
   builder
    .addCase(fetchStatusLoading, (state, action) => {
      state.isLoading = action.payload.isLoading
    })
    .addCase(fetchBalanceNFT, (state, action) => {
        state.balance = action.payload.balance
    })
    .addCase(fetchApprove, (state, action) => {
        state.isApprove = action.payload.isApprove
    })
    .addCase(fetchTokenIds, (state, action) => {
        state.tokenIds = action.payload.tokenIds
    })
    .addCase(fetchTokenInfo, (state, action) => {
        state.nftInfo = action.payload.nftInfo
    })
    .addCase(fetchNFTStaked, (state, action) => {
        state.listNFTToStaked = action.payload.listNFTToStaked
    })
    .addCase(fetchStaked, (state, action) => {
        state.isStaked = action.payload.isStaked
    })
    .addCase(fetchTimeStaked, (state, action) => {
        state.timeStaked = action.payload.timeStaked
    })
    .addCase(fetchAmountStaked, (state, action) => {
        state.userAmountStaked = action.payload.userAmountStaked
    })
    .addCase(fetchNFTIdList, (state, action) => {
        state.nftIdList = action.payload.nftIdList
    })
    .addCase(fetchNFTTypeForStaked, (state, action) => {
        state.nftInfoStaked = action.payload.nftInfoStaked
    })
    .addCase(fetchPenddingReward, (state, action) => {
        state.peddingReward = action.payload.peddingReward
    })
    .addCase(fetchNftIdsUnStake, (state, action) => {
        state.nftIdsUnStake = action.payload.nftIdsUnStake
    })
    .addCase(tabActive, (state, action) => {
        state.isPoolV1 = action.payload.isPoolV1
    })
)