import { createAction } from '@reduxjs/toolkit'
import { ListSellItemsProps, ListSortPrice, NftInfoProps, OwnerType, SellListProps, TokenIdsProps, TotalSellProps, TokenIdsNftOld, TokenIdsPropsV2, NftInfoPropsV2 } from "./type"

export const fetchBalance = createAction<{ balance: number }>('mynft/fetchBalance')
export const fetchPageNumberCount = createAction<{pagenumbercount:number}>('mynft/fetchPageNumberCount')
export const fetchValueSortPrice = createAction<ListSortPrice>('mynft/fetchValueSortPrice')
export const fetchTokenIds = createAction<TokenIdsProps>('mynft/fetchTokenIds')
export const fetchTokenInfo = createAction<NftInfoProps>('mynft/fetchTokenInfo')
export const fetchTotalSellItem = createAction<TotalSellProps>('mynft/fetchTotalSellItem')
export const fetchSellItemsByUser = createAction<SellListProps>('mynft/fetchSellItemsByUser')
export const fetchSellItems = createAction<ListSellItemsProps>('mynft/fetchSellItems')
export const fetchStatusUser = createAction<{statusUser:boolean}>('privatesale/fetchStatus')
export const fetchOwnerNft = createAction<OwnerType>('mynft/fetchOwnerNft')
export const tabActive = createAction<{isTabNewNft:boolean}>('mynft/tabActive')

// for old nft
export const fetchBalanceNftOld = createAction<{ balanceNftOld: number }>('mynft/fetchBalanceNftOld')
export const fetchTokenIdsNftOld = createAction<TokenIdsNftOld>('mynft/fetchTokenIdsNftOld')

// for v2 nft

export const fetchBalanceV2 = createAction<{ balanceV2: number }>('mynft/fetchBalanceV2')
export const fetchTokenIdsV2 = createAction<TokenIdsPropsV2>('mynft/fetchTokenIdsV2')
export const fetchTokenInfoV2 = createAction<NftInfoPropsV2>('mynft/fetchTokenInfoV2')
export const fetchAmountReward = createAction<{amountReward:string}>('mynft/fetchAmountReward')


// for upgrade
export const fetchApproveForAll = createAction<{isApproveForAll:boolean}>('mynft/fetchApproveForAll')
