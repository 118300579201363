import { createReducer } from "@reduxjs/toolkit"
import { fetchDataUser, fetchDataLoadingUser, fetchDataMintNft, fetchDataLoadingMintNft, tabActive, navActive, fetchDataMaxSupply, fetchTime } from "./actions"
import { nftMaxSupply, nftPrice } from "./types"

interface globalStateMarketPlace {
    dataUser: {
        allowance:string,
        balance:string
    },
    isUserLoading:boolean,
    isMintDataLoading:boolean
    type: nftPrice[],
    isLv1:boolean,
    isNavCommission:boolean,
    nft: nftMaxSupply[],
    time: {
        startTime:string,
        endTime:string,
        startTimeWhitelist:string,
        endTimeWhitelist:string
    }
}

export const initialState: globalStateMarketPlace = {
    dataUser: {
        allowance:"0",
        balance:"0"
    },
    isUserLoading:false,
    isMintDataLoading:false,
    type: null,
    isLv1:true,
    isNavCommission:true,
    nft:null,
    time: null
}

export default createReducer(initialState, (builder) => 
   builder
    .addCase(fetchDataUser, (state, action) => {
      state.dataUser = action.payload.dataUser
    })
    .addCase(fetchDataLoadingUser, (state, action) => {
        state.isUserLoading = action.payload.isUserLoading
    })
    .addCase(fetchDataLoadingMintNft, (state, action) => {
        state.isMintDataLoading = action.payload.isMintDataLoading
    })
    .addCase(fetchDataMintNft, (state, action) => {
        state.type = action.payload.type
    })
    .addCase(tabActive, (state, action) => {
        state.isLv1 = action.payload.isLv1
    })
    .addCase(navActive, (state, action) => {
        state.isNavCommission = action.payload.isNavCommission
    })
    .addCase(fetchDataMaxSupply, (state, action) => {
        state.nft = action.payload.nft
    })
    .addCase(fetchTime, (state, action) => {
        state.time = action.payload.time
    })
)      