import { createReducer } from '@reduxjs/toolkit'
import { Decrypts } from 'config/api/decrypts'
import { fecthCaptchaIdByUser, fecthTokenByUser } from './actions'

const token = Decrypts()

interface globalStateAccount {
  tokenByUser: string
  captchaId: string
}
export const initialState: globalStateAccount = {
  tokenByUser: token,
  captchaId: '',
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(fecthTokenByUser, (state, action) => {
      state.tokenByUser = action.payload.tokenByUser
    })
    .addCase(fecthCaptchaIdByUser, (state, action) => {
      state.captchaId = action.payload.captchaId
    }),
)
