import { createReducer } from "@reduxjs/toolkit"
import { 
    fetchTokenInfoV2, fetchPeddingReward, fetchTimeStaked, fetchNFTStaked, fetchNftIdsUnStake, fetchAmountReward
} from "./actions"
import { NFTType, NftAttribute, TokenId } from "./type"

interface globalStateNFTPool {
    listNftAttribute: NftAttribute[],
    penddingReward: string,
    timeStaked: {
        startTimeStaked:number,
        endTimeStaked:number,
    },
    listNFTToStaked: number[],
    nftIdsUnStake: number[],
    amountReward:string
}

export const initialState: globalStateNFTPool = {
    listNftAttribute: [],
    penddingReward: "0",
    timeStaked: {
        startTimeStaked:0,
        endTimeStaked:0,
    },
    listNFTToStaked: [],
    nftIdsUnStake: [],
    amountReward:"0"
}

export default createReducer(initialState, (builder) => 
   builder
    .addCase(fetchTokenInfoV2, (state, action) => {
      state.listNftAttribute = action.payload.listNftAttribute
    })
    .addCase(fetchPeddingReward, (state, action) => {
        state.penddingReward = action.payload.penddingReward
    })
    .addCase(fetchTimeStaked, (state, action) => {
        state.timeStaked = action.payload.timeStaked
    })
    .addCase(fetchNFTStaked, (state, action) => {
        state.listNFTToStaked = action.payload.listNFTToStaked
    })
    .addCase(fetchNftIdsUnStake, (state, action) => {
        state.nftIdsUnStake = action.payload.nftIdsUnStake
    })
    .addCase(fetchAmountReward, (state, action) => {
        state.amountReward = action.payload.amountReward
    })
)