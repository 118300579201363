import { createAction } from '@reduxjs/toolkit'
import { TokenIdsProps, NftInfoProps, TimeStakedProps, NftInfoStakedProps, ListNftAttribute } from "./type"

// for core nft pool v2

export const fetchTokenInfoV2 = createAction<ListNftAttribute>('nftpoolV2/fetchTokenInfoV2')
export const fetchPeddingReward = createAction<{penddingReward: string}>('nftpoolV2/fetchPeddingReward')
export const fetchTimeStaked = createAction<TimeStakedProps>('nftpoolV2/fetchTimeStaked')
export const fetchNFTStaked = createAction<{ listNFTToStaked: number[] }>('nftpool/fetchNFTStaked')
export const fetchNftIdsUnStake = createAction<{ nftIdsUnStake: number[] }>('nftpool/fetchNftIdsUnStake')
export const fetchAmountReward = createAction<{amountReward:string}>('nftpool/fetchAmountReward')
