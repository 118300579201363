import { createAction } from '@reduxjs/toolkit'
import { ApproveType, DataUser, Items, ListSellItemsProps, SellItemsProps, TotalSellProps } from "./type"

export const fetchUserAllowance = createAction<ApproveType>('marketplace/fetchUserAllowance')
export const fetchSellItems = createAction<ListSellItemsProps>('marketplace/fetchSellItems')
export const fetchTotalSellItem = createAction<TotalSellProps>('marketplace/fetchTotalSellItem')
export const fetchPageNumberCountOnSale = createAction<{pagenumbercountOnSale:number}>('marketplace/fetchPageNumberCountOnSale')
export const fetchSellItemsByUser = createAction<{listItemsByUser:SellItemsProps[]}>('marketplace/fetchSellItemsByUser')
export const fetchStatusFetching = createAction<{isFetching:boolean}>('marketplace/fetchStatusFetching')
export const fetchPageNumberCountMarketplace = createAction<{pagenumbercountMarketplace:number}>('marketplace/fetchPageNumberCountMarketplace')
export const fetchItemsOnMarketplace = createAction<{listMarketplace:SellItemsProps[]}>('marketplace/fetchItemsOnMarketplace')
export const fetchDataUser = createAction<DataUser>('marketplace/fetchDataUser')
export const fetchDataUserStatus = createAction<{isDataFetching:boolean}>('marketplace/fetchDataUserStatus')
export const fetchItemsOnSell = createAction<Items>('marketplace/fetchItems')
