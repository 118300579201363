import { createAction } from '@reduxjs/toolkit'
import { dataUser, dataMinterNft, dataAmountMintNFT, dataMaxSupplyMintNFT, timeMintNFT } from "./types"

export const fetchDataUser = createAction<dataUser>('minterNft/fetchDataUser')
export const fetchDataLoadingUser = createAction<{isUserLoading:boolean}>('minterNft/fetchDataLoadingUser')
export const fetchDataMintNft = createAction<dataAmountMintNFT>('minterNft/fetchDataMintNft')
export const fetchDataMaxSupply = createAction<dataMaxSupplyMintNFT>('minterNft/fetchDataMaxSupply')
export const fetchTime = createAction<timeMintNFT>('minterNft/fetchTime')
export const fetchDataLoadingMintNft = createAction<{isMintDataLoading:boolean}>('minterNft/fetchDataLoadingMintNft')
export const tabActive = createAction<{isLv1:boolean}>('minterNft/tabActive')
export const navActive = createAction<{isNavCommission:boolean}>('minterNft/navActive')