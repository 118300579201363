import React from "react";
import styled from "styled-components";
import { PolymorphicComponent } from "../../util/polymorphic";
import Button from "../Button/Button";
import { BaseButtonProps, variants } from "../Button/types";
import { ButtonMenuItemProps } from "./types";

interface InactiveButtonProps extends BaseButtonProps {
  forwardedAs: BaseButtonProps["as"];
}

const InactiveButton: PolymorphicComponent<InactiveButtonProps, "button"> = styled(Button)<InactiveButtonProps>`
      background-color: transparent;
      color: ${({ theme, variant }) => theme.colors.text};
      &:hover:not(:disabled):not(:active) {
        background-color: transparent;
        border: 1px solid ${({ theme }) => theme.colors.text};
        color: ${({ theme }) => theme.colors.text};
      }
`;
const CsButton = styled(Button)`
    background-color: ${({ theme }) => theme.colors.text};
    color: ${({ theme }) => theme.colors.background};
`
const ButtonMenuItem: PolymorphicComponent<ButtonMenuItemProps, "button"> = ({
  isActive = false,
  variant = variants.PRIMARY,
  as,
  ...props
}: ButtonMenuItemProps) => {
  if (!isActive) {
    return <InactiveButton forwardedAs={as} variant={variant} {...props} />;
  }

  return <CsButton as={as} variant={variant} {...props} />;
};

export default ButtonMenuItem;
