import styled from 'styled-components'
import { Box } from '@pancakeswap/uikit'

const Card = styled(Box)<{
  width?: string
  padding?: string
  border?: string
  borderRadius?: string
}>`
  width: ${({ width }) => width ?? '100%'};
  padding: ${({ padding }) => padding ?? '1.25rem'};
  border: ${({ border }) => border};
`
export default Card

export const LightCard = styled(Card)`
 
`
export const CsLightCard = styled(Card)`
 
`

export const LightGreyCard = styled(Card)`
`

export const GreyCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.colors.text};
`
