import { ContextApi } from '@pancakeswap/localization'
import {
  EarnFillIcon,
  EarnIcon, MenuItemsType, PoolsIcon, ExChangeIcon, MarketplaceIcon, LaunchpabIcon, VotingIcon, AccountIcon,PartnerFillIcon, PartnerIcon
  
} from '@pancakeswap/uikit'
import { ChainId } from '@pancakeswap/sdk'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
import { SUPPORT_ONLY_BSC, SUPPORT_ZAP } from 'config/constants/supportChains'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label: t('Exchange'),
      href: '/swap',
      icon: ExChangeIcon,
      fillIcon: ExChangeIcon,
      showItemsOnMobile: true,
      items: [
        {
          label: t('Swap'),
          href: '/swap',
        },
        {
          label: t('Liquidity'),
          href: '/liquidity',
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t('Earn'),
      icon: EarnIcon,
      fillIcon: EarnIcon,
      href: '/farms',
      showItemsOnMobile: true,
      items: [
        {
          label: t('Farms'),
          href: '/farms',
          supportChainIds: [ChainId.BSC, ChainId.BSC_TESTNET],
        },
        {
          label: t('Pools'),
          href: '/pools',
          supportChainIds: [ChainId.BSC, ChainId.BSC_TESTNET],
        },
        {
          label: t('NFT Pool'),
          href: '/nftpool',
          supportChainIds: [ChainId.BSC, ChainId.BSC_TESTNET],
        }
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t('Launchpad'),
      icon: LaunchpabIcon,
      fillIcon: LaunchpabIcon,
      href: '/privatesale',
      showItemsOnMobile: true,
      items: [
        {
          label: t('Private sale'),
          href: '/privatesale',
          supportChainIds: [ChainId.BSC, ChainId.BSC_TESTNET],
        },
        {
          label: t('Public sale'),
          href: 'https://ido.pibridge.org',
          type:1
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t('Voting'),
      icon: VotingIcon,
      fillIcon: VotingIcon,
      href: '/voting',
      showItemsOnMobile: false,
      items: [
        {
          label: t('Voting'),
          href: '/voting',
          supportChainIds: [ChainId.BSC, ChainId.BSC_TESTNET],
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t('Marketplace'),
      icon: MarketplaceIcon,
      fillIcon: MarketplaceIcon,
      href: '/marketplace',
      showItemsOnMobile: false,
      items: [
        {
          label: t('Marketplace'),
          href: '/marketplace',
          supportChainIds: [ChainId.BSC, ChainId.BSC_TESTNET],
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    }
  ].map((item) => addMenuItemSupported(item, chainId))

export default config
