import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="36" height="36" viewBox="0 0 36 36" fill="none" {...props}>
        <path d="M27 8.86523L9 27.1352" stroke="#494949" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9 8.86523L27 27.1352" stroke="#494949" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  );
};

export default Icon;
