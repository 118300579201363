import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
        <Svg viewBox="0 0 26 24" fill="none" style={{fill:"none"}} xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M17.3649 0.826172L24.3999 7.87617H1.59985" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.64987 23.175L1.59985 16.125H24.3999" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  );
};

export default Icon;
