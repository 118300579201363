import { createReducer } from '@reduxjs/toolkit'
import { fetchDataIdo, fetchDataUser, fetchStatus, fetchStageList } from "./actions"

interface globalStateIdo {
    dataIdo: {
        startBuyTime:number,
        endBuyTime:number,
        tokenPerSlot:number,
        totalOfSlot:number,
        payAmountPerSlot:number,
        limitTime:number
    },
    data: {
        paidPrivateMap:boolean,
        vestingMap:number,
        deminomator:number,
        tokenPerSlot:number
        isBacklist:boolean
    },
    status:boolean,
    stageList: {
        id: number,
        percent:number,
        startTime: number
    }
}
export const initialState: globalStateIdo = {
    dataIdo: {
        startBuyTime:0,
        endBuyTime:0,
        tokenPerSlot:0,
        totalOfSlot:0,
        payAmountPerSlot:0,
        limitTime:0
    },
    data: {
        paidPrivateMap:false,
        vestingMap:0,
        deminomator:0,
        tokenPerSlot:0,
        isBacklist:false
    },
    status:false,
    stageList: {
        id: 0,
        percent:0,
        startTime: 0
    }
}
export default createReducer(initialState, (builder) =>
  builder
    .addCase(fetchDataIdo, (state, action) => {
      state.dataIdo = action.payload.dataIdo
    })
    .addCase(fetchDataUser, (state, action) => {
        state.data = action.payload.data
    })
    .addCase(fetchStatus, (state, action) => {
        state.status = action.payload.status
    })
    .addCase(fetchStageList, (state, action) => {
        state.stageList = action.payload.stageList
    })
)