import { createReducer } from "@reduxjs/toolkit"
import { fetchPageNumberCountOnSale, fetchSellItems, fetchSellItemsByUser, fetchStatusFetching, fetchTotalSellItem, fetchUserAllowance, fetchPageNumberCountMarketplace, fetchItemsOnMarketplace, fetchDataUser, fetchDataUserStatus, fetchItemsOnSell } from "./actions"
import {  ApproveType, SellItemsProps, UserItems } from "./type"

interface globalStateMarketPlace {
    isApprove: boolean,
    totalSell?:number,
    listItems?:SellItemsProps[],
    listItemsByUser?:SellItemsProps[],
    pagenumbercountOnSale:number,
    isFetching:boolean,
    pagenumbercountMarketplace:number,
    listMarketplace:SellItemsProps[],
    dataUser:UserItems,
    isDataFetching:boolean
    item: {
        saleId:number,
        nftId:number,
        priceListing:string,
        isSold:boolean,
        seller:string,
        buyer:string,
        currency:string,
        nft:string,
        nftType:number,
        isExit:boolean
    }
}

export const initialState: globalStateMarketPlace = {
    isApprove: false,
    totalSell:0,
    listItems:[],
    listItemsByUser:[],
    pagenumbercountOnSale:0,
    isFetching:true,
    pagenumbercountMarketplace:0,
    listMarketplace:[],
    dataUser:{
        allowance:"0",
        balance:"0"
    },
    isDataFetching:true,
    item: {
        saleId:0,
        nftId:0,
        priceListing:"0",
        isSold:true,
        seller:"",
        buyer:"",
        currency:"",
        nft:"",
        nftType:0,
        isExit:false
    },
}

export default createReducer(initialState, (builder) => 
   builder
    .addCase(fetchUserAllowance, (state, action) => {
      state.isApprove = action.payload.isApprove
    })
    .addCase(fetchTotalSellItem, (state, action) => {
        state.totalSell = action.payload.totalSell
    })
    .addCase(fetchSellItems, (state, action) => {
        state.listItems = action.payload.listItems
    })
    .addCase(fetchPageNumberCountOnSale, (state, action) => {     
        state.pagenumbercountOnSale = action.payload.pagenumbercountOnSale
    })
    .addCase(fetchSellItemsByUser, (state, action) => {
        state.listItemsByUser = action.payload.listItemsByUser
    })
    .addCase(fetchStatusFetching, (state, action) => {
        state.isFetching = action.payload.isFetching
    })
    .addCase(fetchPageNumberCountMarketplace, (state, action) => {
        state.pagenumbercountMarketplace = action.payload.pagenumbercountMarketplace
    })
    .addCase(fetchItemsOnMarketplace, (state, action) => {
        state.listMarketplace = action.payload.listMarketplace
    })
    .addCase(fetchDataUser, (state, action) => {
        state.dataUser = action.payload.dataUser
    })
    .addCase(fetchDataUserStatus, (state, action) => {
        state.isDataFetching = action.payload.isDataFetching
    })
    .addCase(fetchItemsOnSell, (state, action) => {
        state.item = action.payload.item
    })
)    