import { createReducer } from "@reduxjs/toolkit"
import { 
    fetchBalance, 
    fetchOwnerNft, 
    fetchPageNumberCount, 
    fetchSellItems, 
    fetchSellItemsByUser, 
    fetchStatusUser, 
    fetchTokenIds, 
    fetchTokenInfo, 
    fetchTotalSellItem, 
    fetchValueSortPrice, 
    tabActive,
    fetchBalanceNftOld,
    fetchTokenIdsNftOld,
    fetchBalanceV2,
    fetchTokenIdsV2,
    fetchTokenInfoV2, fetchApproveForAll, fetchAmountReward
} from "./actions"
import { BoxType, SelectSorPrice, SellItemsProps, TokenId, TokenIdOld } from "./type"



interface globalStateMynft {
    balance?: number,
    totalSell?:number,
    listItems?:SellItemsProps[],
    listItemsSell?:SellItemsProps[],
    tokenIds?:TokenId[],
    nftInfo?:BoxType[],
    listsortprice?:SelectSorPrice,
    pagenumbercount?:number
    statusUser:boolean,
    owner: string,
    isTabNewNft:boolean,
    balanceNftOld: number,
    tokenIdsOld: TokenIdOld[],
    balanceV2: number,
    tokenIdsV2:TokenId[],
    nftInfoV2:BoxType[],
    isApproveForAll:boolean,
    amountReward:string
}

export const initialState: globalStateMynft = {
    balance:0,
    totalSell:0,
    listItems:[
        {
            saleId:0,
            nftId:null,
            priceListing:0,
            isSold:false,
            seller:"",
            buyer:"",
            currency:"",
            nft:"",
            boxType:0,
            adminSale:false
        }
    ],
    listItemsSell:[
        {
            saleId:0,
            nftId:null,
            priceListing:0,
            isSold:false,
            seller:"",
            buyer:"",
            currency:"",
            nft:"",
            boxType:0,
            adminSale:false
        }
    ],
    tokenIds:[],
    nftInfo:[],
    listsortprice:{
        labelsort:"",
        valuesort:""
    },
    pagenumbercount:0,
    statusUser:false,
    owner: "0x00000",
    isTabNewNft:true,
    balanceNftOld: 0,
    tokenIdsOld: [],
    balanceV2: 0,
    tokenIdsV2:[],
    nftInfoV2:[],
    isApproveForAll:false,
    amountReward:"0"
}

export default createReducer(initialState, (builder) => 
   builder
    .addCase(fetchTotalSellItem, (state, action) => {
      state.totalSell = action.payload.totalSell
    })
    .addCase(fetchSellItemsByUser, (state, action) => {
        state.listItemsSell = action.payload.listItemsSell
    })
    .addCase(fetchSellItems, (state, action) => {
        state.listItems = action.payload.listItems
    })
    .addCase(fetchBalance, (state, action) => {
        state.balance = action.payload.balance
    })
    .addCase(fetchTokenIds, (state, action) => {
        state.tokenIds = action.payload.tokenIds
    })
    .addCase(fetchTokenInfo, (state, action) => {
        state.nftInfo = action.payload.nftInfo
    })
    .addCase(fetchValueSortPrice, (state, action) => {     
        state.listsortprice = action.payload.listsortprice
    })
    .addCase(fetchPageNumberCount, (state, action) => {     
        state.pagenumbercount = action.payload.pagenumbercount
    })
    .addCase(fetchStatusUser, (state, action) => {     
        state.statusUser = action.payload.statusUser
    })
    .addCase(fetchOwnerNft, (state, action) => {
        state.owner = action.payload.owner
    })
    .addCase(tabActive, (state, action) => {
        state.isTabNewNft = action.payload.isTabNewNft
    })
    .addCase(fetchBalanceNftOld, (state, action) => {
        state.balanceNftOld = action.payload.balanceNftOld
    })
    .addCase(fetchTokenIdsNftOld, (state, action) => {
        state.tokenIdsOld = action.payload.tokenIdsOld
    })
    .addCase(fetchBalanceV2, (state, action) => {
        state.balanceV2 = action.payload.balanceV2
    })
    .addCase(fetchTokenIdsV2, (state, action) => {
        state.tokenIdsV2 = action.payload.tokenIdsV2
    })
    .addCase(fetchTokenInfoV2, (state, action) => {
        state.nftInfoV2 = action.payload.nftInfoV2
    })
    .addCase(fetchAmountReward, (state, action) => {
        state.amountReward = action.payload.amountReward
    })
)