import { createAction } from '@reduxjs/toolkit'
import { TokenIdsProps, NftInfoProps, TimeStakedProps, NftInfoStakedProps } from "./type"

export const fetchBalanceNFT = createAction<{ balance: number }>('nftpool/fetchBalanceNFT')
export const fetchTokenIds = createAction<TokenIdsProps>('nftpool/fetchTokenIds')
export const fetchTokenInfo = createAction<NftInfoProps>('nftpool/fetchTokenInfo')
export const fetchStatusLoading = createAction<{ isLoading: boolean }>('nftpool/fetchStatusLoading')
export const fetchApprove = createAction<{ isApprove: boolean }>('nftpool/fetchApprove')
export const fetchNFTStaked = createAction<{ listNFTToStaked: number[] }>('nftpool/fetchNFTStaked')
export const fetchStaked = createAction<{ isStaked: boolean }>('nftpool/fetchStaked')
export const fetchTimeStaked = createAction<TimeStakedProps>('nftpool/fetchTimeStaked')
export const fetchAmountStaked = createAction<{userAmountStaked: number}>('nftpool/fetchAmountStaked') 
export const fetchNFTIdList = createAction<{nftIdList: number[]}>('nftpool/fetchNFTIdList')
export const fetchNFTTypeForStaked = createAction<NftInfoStakedProps>('nftpool/fetchNFTTypeForStaked') 
export const fetchPenddingReward = createAction<{peddingReward:string}>('nftpool/fetchPenddingReward') 
export const fetchNftIdsUnStake = createAction<{ nftIdsUnStake: number[] }>('nftpool/fetchNftIdsUnStake')
export const tabActive = createAction<{isPoolV1:boolean}>('nftpool/tabActive')
